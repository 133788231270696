.container{
    display: flex;
    position: fixed;
}


 .sideBarItemContainer{
     width: 35px;
     cursor: pointer;
 }
 .sideBarItemContainer img{
    cursor: pointer;
    margin: 10px; 
}

@media only screen and (min-width: 100px) {
    .container{
        bottom: 0;
        left: 0;
        width: 250px;
        flex-direction: row;
        justify-content: stretch;
        background: rgba(256, 256, 256, 0.8);
    }
    .sideBarItemContainer{
        margin-left: 5px;
    }
    .sideBarItemContainer img{
        width: 80%;
    }
  }

@media only screen and (min-width: 600px) {
    .container{
        bottom: 10px;
        left: 10px;
        width: 35px;
        flex-direction: column;
        background: transparent;
    }
    .sideBarItemContainer{
        margin-left: 0;
    }
    .sideBarItemContainer img{
        width: 100%;
    }
  }

/* Search */
.searchContainer{
    overflow: visible;
    position: relative;
}

.searchForm{
    position: absolute;
    top: 10%;
    left: 160%;
    display: flex;
    flex-direction: row;
    color: #414347;
    height: 40px;
    font-family: Lora,Helvetica Neue,Helvetica,sans-serif;
    box-shadow: 0 0 3rem rgba(8,39,54,.2);
}
.searchForm input{
    padding: 0.5rem;
    width: 140px;
    background: white;
    border: none;
    vertical-align: middle;
    line-height: 3rem;
    font-size: 1em;
    font-family: Lora,Helvetica Neue,Helvetica,sans-serif;
    color: #414347;
    
}
.searchForm input:focus{
    outline-width: 0;
}
.searchForm button{
    vertical-align: middle;
    padding: 0.7rem 1rem;
    width: 120px;
    border: none;
    cursor: pointer;
    background: #082736;
    color: #ffffff;
    font-weight: 700;
    font-size: 1em;
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
    letter-spacing: .1em;
    text-transform: uppercase;
    
}
.searchForm button:focus{
    outline-width: 0;
}
.searchForm button:hover{
    background: #AE1F23;
}




/* About */
.aboutContainer{
    overflow: visible;
    position: relative;
}

.aboutPopup{
    position: absolute;
    left: 160%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.5em;
    background: #fff;
    width: 400px;
    font-family: Lora,Helvetica Neue,Helvetica,sans-serif;
    box-shadow: 0 0 3rem rgba(8,39,54,.2);
}

.aboutText{
    font-style: italic;
    color: #222222; 
    font-size: .9em;
}

.aboutLinksPanel{
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.aboutLinksPanel img{
    width: auto;
    height: auto;
    max-width: 25px;
    max-height: 25px;
}

.aboutLinkText{
    margin-left: 5px;
    color: #414347;
    font-size: 0.85rem;
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
    font-weight: 700;
    font-style: normal;
}
.aboutLinkText:hover{
    color: #006DA3;
}

.linkContainer{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
}


