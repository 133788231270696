.container{
    margin-top: 17vw;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    padding-bottom: 80px;
}

.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}

@media only screen and (min-width: 100px) {
    .wrapper{
        width: 100%;
    }
  }

@media only screen and (min-width: 600px) {
    .wrapper{
        width: 80%;
    }
  }

.container h1{
    align-self: center;
    margin-bottom: 1em;
    color: #082736;
}

.section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statisticsGrid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-grow: 1;
    margin: 0 1vw 0 1vw;
}
.statisticsGrid h3{
    color: #082736;
    align-self: center;
    font-size: 1em;
}
.statisticsGridRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0;
    background: hsla(0,0%,100%,.9);
    padding: .5rem;
    cursor: pointer;
    text-decoration: none;
}
.statisticsGridRowTitle{
    color: #414347;
    font-weight: 700;
    font-family: Lora,Helvetica Neue,Helvetica,sans-serif;
    word-wrap: normal;
 }
.statisticsGridRowData{
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
    font-style: italic;
    font-weight: 700;
    white-space: nowrap;
    --padding-left: 1rem;
    color: #007dae;
}

.columnsContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}