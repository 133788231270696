  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/lora/static/Lora-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: bold;
    src: url('./assets/fonts/lora/static/Lora-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: normal;
    src: url('./assets/fonts/lora/static/Lora-Italic.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: bold;
    src: url('./assets/fonts/lora/static/Lora-BoldItalic.ttf') format('truetype');
  }
    

body {
    background-color: #d5e9f5;
    color: #414347;
    font-size: 16px;
    font-family: 'Lora', serif;
}

/*Not used - for reference purposes only*/
.sideBarItemColor{
  color: #45616F;
}
.sideBarItemColor:hover{
  color: #082736;
}