.container{
    background: #ffffffe6;
    padding: 25px 0 12px 0;
    display: flex;
    max-width: 100%;
    flex-direction: column;
}

.container textarea{
    min-width: 55vw;
    max-width: 55vw;
    margin-bottom: 40px;
}

.content{
    --flex: 1 0;
    padding: 4vw;
    overflow: auto;
}

.content p{
    line-height: 1.6;
    display: block;
    font-size: 1em;
    font-weight: normal;
}

.content img{
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 150vh;
}

.content em{
    background: #d5e9f5;
    padding: 0 .4em;
}

.content a{
    text-decoration: none;
    color: #ae1f23;
}

.buttons{
    display: flex;
    padding: 0 4vw 0 4vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.buttons a{
    text-decoration: none;
}

.signature{
    margin-top: 10px;
    margin-right: 4vw;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-style: italic;
    font-family: Lora,Helvetica Neue,Helvetica,sans-serif;
}

.userName{
    text-decoration: none;
    font-size: 1em;
    color: #414347;
}
.userName:hover{
    text-decoration: underline;
}

.date{
    font-size: 0.8em;
    text-decoration: none;
    color: #414347;
}
.date:hover{
    text-decoration: underline;
}


.aside{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
}

.asideItem{
    flex: 1 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
    font-weight: 700;
    border-top-width: 2.4px;
    border-top-style: solid;
    font-size: 0.81rem;
    padding-top: 12px;
    margin: 2px;
}

.asideItemTag{
    border-top-color: #080;
    color: #080;
}

.asideItemPermalink{
    border-top-color: #007dae;
    color: #007dae;
}

.asideItemRaw{
    border-top-color: #7b185d;
    color: #7b185d;
}

.asideItemLikes{
    border-top-color: #ee7567;
    color: #ee7567;
}





@media only screen and (min-width: 100px) {
    .content h2{
        font-size: 1.25em;
    }
  }

@media only screen and (min-width: 600px) {
    .content h2{
        font-size: 1.5em;
    }
  }