.background{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 50;
}

.container {
    position:fixed;
    background: white;
    width: auto;
    height: auto;
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 0;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}