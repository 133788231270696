.container{
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;
}

.button{
    background: hsla(0,0%,100%,.9);
    padding: 10px 20px;
    margin: 0 10px;
    text-transform: uppercase;
    text-decoration: none;
    color: #007dae;
    font-weight: bold;
    font-size: .8em;
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
}