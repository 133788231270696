.container{
    position: fixed;
    padding: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subContainer{
    display: flex;
    flex-direction: column;
}

.header{
    color: #082736;
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .1em;
    font-weight: 900;
    font-size: 7vw;
    line-height: 1;
    cursor: pointer;
}

.subHeaderContainer{
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.twitterImage{
    margin-right: 8px;    
}

.twitterHeader{
    color: #006da3;
    font-size: 14px;
    font-weight: 700;
    font-family: Raleway,Helvetica Neue,Helvetica,sans-serif;
    letter-spacing: 0.1em;
    margin-right: 0.5em;
}



@media only screen and (min-width: 100px) {
    .container{
        top: 0;
        z-index: 10;
        background: rgba(255, 255, 255, 0.9);
    }
  }

@media only screen and (min-width: 600px) {
    .container{
        top: 1.5vw;
        z-index: -1;
        background: transparent;
    }
  }