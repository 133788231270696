.container{
  margin-top: 10vw;
  display: flex;
  height: calc(100vh - 10vw);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.textContainer{
  width: 81%;
  flex: 1;
}

.htmlContainer{
  margin-top: 15px;
  flex: 1;
  width: 80%;
}

.buttonsPanel{
  height: 5%;
  min-height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; 
  align-items: center;
}

:global(.public-DraftEditor-content) {
  height: 35vh !important;
  overflow: auto;
}
