.container{
    margin-top: 17vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.searchResultsHeaderContainer{
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.searchResultsHeader{
    color: #414347;
    font-family: Lora,Helvetica Neue,Helvetica,sans-serif;
    font-size: 1.6em;
}
.authorContactsPanel{
    margin-left: 10px;
}
.authorContactsPanel a{
    text-decoration: none;
    padding: 5px;
}
.authorContactsPanel a img{
    width: 20px;
}
