.container{
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 15;
}

.login{
    color: #006da3;
    font-weight: 700;
    text-decoration: none;
}

.picture{
    width: 5vw;
    min-width: 30px;
    max-width: 60px;
    border-radius: 50%;
    cursor: pointer;
}
.picturePlaceholder{
  width: 5vw;
  height: 5vw;
  min-width: 30px;
  min-height: 30px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(190, 151, 137);
  display: flex;
  align-items: center;
  justify-content: center;
}
.picturePlaceholder span{
  font-size: 2.5vw;
  font-weight: bold;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipContainer .tooltip {
  --visibility: hidden;
  width: 200px;
  background-color: #082736B3;
  --opacity: 0.7;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .tooltipContainer:hover .tooltip {
  visibility: visible;
} */

.tooltipContainer .tooltip .name {
  font-size: 18px;
}

.tooltipContainer .tooltip .loggedin {
  font-size: 14px;
  margin-bottom: 20px;
}




.closeButton{
  width: auto;
  height: auto;
  position: absolute;
  right: 5px;
  top: 7px;
  padding: 2px;
  border: 1px solid transparent;
}
.closeButton:hover{
  border: 1px solid #fff;
  border-radius: 5px;
}
.closeButton:active{
  border: 2px #fff inset;
  border-radius: 5px;
}

.userSettingsInputContainer{
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}
.userSettingsInput{
  padding: 0.5rem;
  background: #fff;
  border: 1px #888 solid;
  border-radius: 5px;
  vertical-align: middle;
  height: 0.4rem;
  font-size: 0.8em;
  font-family: Helvetica,sans-serif;
  color: #414347;
  margin-top: -1px;
  margin-left: 5px;
  background-color: #F7F7F7;
}
.userSettingsInput::placeholder{
  color: #b0b0b0;
}
.userSettingsInput:focus{
  outline-width: 0;
}
.userSettingsIcon{
  height: auto;
  width: auto;
}

.userSettingsLink{
  margin-top: 15px;
  font-size: 16px;
  cursor: pointer;
  flex-grow: 1;
  align-self: center;
}
.userSettingsLink:hover{
  color: #ff6600;
}

.bottomPanelContainer {
  align-self: stretch;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}
.bottomPanelContainer .save {
  font-size: 16px;
  cursor: pointer;
  flex-grow: 1;
  text-align: left;
}
.bottomPanelContainer .save:hover {
  color: #ff6600;
}
.bottomPanelContainer .logout {
  font-size: 16px;
  cursor: pointer;
  flex-grow: 1;
  text-align: right;
}
.bottomPanelContainer .logout:hover {
  color: #ff6600;
}